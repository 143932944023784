/* You can add global styles to this file, and also import other style files */
@import './assets/satoshi';

body {
  margin: 0px !important;
  background: #f6f4f0;
  background-color: #f6f4f0;
  height: 100% !important;
}

body,
p,
h1,
span {
  font-family: Satoshi-Variable;
}

::-webkit-scrollbar {
  display: none;
}

html {
  body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    margin: '0px !important';
  }
  height: -webkit-fill-available;
}
#root {
  width: 100%;
  height: 100%;
}
